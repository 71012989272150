import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {withStyles} from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/components/embedSocialStyle.js";


// https://help.embedsocial.com/en/articles/4797000-how-to-embed-code-in-a-react-application
// https://embedsocial.com/admin/social_feed

class EmbedSocial extends React.Component {
  constructor(props) {
    super(props)
    this.componentDidMount = this.componentDidMount.bind(this)
  }
  componentDidUpdate(prevProps, prevState, snapshot) {

  }


  componentDidMount() {
    const script = document.createElement("script");
    script.src = "//embedsocial.com/cdn/ht.js";
    script.async = true;
    document.body.appendChild(script);
    // let ad = document.getElementsByClassName('embedsocial-hashtag-iframe').contentWindow.document.getElementById('#es-header')
  }

  render() {
    const {classes} = this.props;
    return (
      <div className={classNames(classes.main)}>
        <script src="https://embedsocial.com/cdn/ht.js" type="text/javascript"/>
        <div style={{width: '70%', marginTop: "11rem",}}
             className="embedsocial-hashtag"
             data-ref={this.props.refId}
             id={this.props.refId}/>
      </div>
    );
  }
}

EmbedSocial.defaultProps = {
  refId: "ff836d9c788b0173c0300977416fdc15d7e9a282"
};

EmbedSocial.propTypes =
  {
    refId: PropTypes.string,
  };

export default withStyles(styles)(EmbedSocial)
