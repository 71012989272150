import {container, title} from "assets/jss/material-kit-react.js";

const schedulePageStyle = __theme => ({
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    position: "relative",
    zIndex: "3",
    width: "100%",
  },
  mainCalendar: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",

  },
  "@keyframes dropDown": {
    "0%": {bottom: "400px", opacity: 0.5},
    "50%": {bottom: "200px", opacity: 0.5},
    "100%": {bottom: "0px", opacity: 1},
  },
  animateDrop: {
    position: "relative",
    margin: "0 auto",
    "transform-origin": "10px 10px",
    animation: `$dropDown 1s ease-in forwards`,
    "animation-play-state": "running",
  },
  stepRoot: {
    width: '100%',
  }
})

export default schedulePageStyle;
