import {Octokit} from "@octokit/rest";

const _GH_ = new Octokit({auth: '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',}).rest.repos

async function SaveGit(data, filePath = "private/calendarEvents/myEvents.json") {
  const date = new Date()
  const timeStampHash = `${date.toISOString()}-${Math.floor(Math.random() * 1000)}`
  try {
    const file = await GetGitFile(filePath)
    const sha = file.data.sha
    return await _GH_.createOrUpdateFileContents(
      {
        owner: 'schanjr-io',
        repo: 'schanjr-io.github.io',
        path: filePath,
        message: `saved from calendar app - ${timeStampHash}`,
        content: btoa(data),
        branch: 'master',
        sha: sha
      })
  } catch (e) {
    console.error('warning', `Try again later: ${e}`)
    return -1
  }
}

async function GetContent(filePath= "private/calendarEvents/myEvents.json") {
  const contents = await GetGitFile(filePath)
  try {
    return atob(contents.data.content)
  }
  catch (e) {
    return e
  }
}

async function GetGitFile(filePath = "private/calendarEvents/myEvents.json") {
  try {
   return await _GH_.getContent(
      {
        owner: 'schanjr-io',
        repo: 'schanjr-io.github.io',
        path: filePath,
      }
    )
  } catch (e) {
    return e
  }
}

export {SaveGit, GetContent}
