// import {
//   grayColor,
//   roseColor,
//   primaryColor,
//   infoColor,
//   successColor,
//   warningColor,
//   dangerColor
// } from "assets/jss/material-kit-react.js";

import {cardTitle, cardLink, cardSubtitle} from "assets/jss/material-kit-react.js";

const appointmentCardStyle = {
  // inputLabel: {
  //   underline: {
  //     '&:after': {
  //       'border-color': '#4caf50' //#9c27b0 default color #3f51b5
  //     }
  //   }
  // },
  cardTitle: {...cardTitle},
  cardLink: {...cardLink},
  cardSubtitle: {...cardSubtitle},
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '25em'
  },
  input: {
    display: 'none',
  }
  // primary: {
  //   backgroundColor: primaryColor,
  //   boxShadow:
  //     "0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)",
  //   "&:hover,&:focus": {
  //     backgroundColor: primaryColor,
  //     boxShadow:
  //       "0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2)"
  //   }
  // },
  // info: {
  //   backgroundColor: infoColor,
  //   boxShadow:
  //     "0 2px 2px 0 rgba(0, 188, 212, 0.14), 0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12)",
  //   "&:hover,&:focus": {
  //     backgroundColor: infoColor,
  //     boxShadow:
  //       "0 14px 26px -12px rgba(0, 188, 212, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 188, 212, 0.2)"
  //   }
  // },
  // success: {
  //   backgroundColor: successColor,
  //   boxShadow:
  //     "0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12)",
  //   "&:hover,&:focus": {
  //     backgroundColor: successColor,
  //     boxShadow:
  //       "0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)"
  //   }
  // },
  // warning: {
  //   backgroundColor: warningColor,
  //   boxShadow:
  //     "0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12)",
  //   "&:hover,&:focus": {
  //     backgroundColor: warningColor,
  //     boxShadow:
  //       "0 14px 26px -12px rgba(255, 152, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 152, 0, 0.2)"
  //   }
  // },
  // danger: {
  //   backgroundColor: dangerColor,
  //   boxShadow:
  //     "0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12)",
  //   "&:hover,&:focus": {
  //     backgroundColor: dangerColor,
  //     boxShadow:
  //       "0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2)"
  //   }
  // },
  // rose: {
  //   backgroundColor: roseColor,
  //   boxShadow:
  //     "0 2px 2px 0 rgba(233, 30, 99, 0.14), 0 3px 1px -2px rgba(233, 30, 99, 0.2), 0 1px 5px 0 rgba(233, 30, 99, 0.12)",
  //   "&:hover,&:focus": {
  //     backgroundColor: roseColor,
  //     boxShadow:
  //       "0 14px 26px -12px rgba(233, 30, 99, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(233, 30, 99, 0.2)"
  //   }
  // },
  // white: {
  //   "&,&:focus,&:hover,&:visited": {
  //     backgroundColor: "#FFFFFF",
  //     color: grayColor
  //   }
  // },
  // twitter: {
  //   backgroundColor: "#55acee",
  //   color: "#fff",
  //   boxShadow:
  //     "0 2px 2px 0 rgba(85, 172, 238, 0.14), 0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12)",
  //   "&:hover,&:focus,&:visited": {
  //     backgroundColor: "#55acee",
  //     color: "#fff",
  //     boxShadow:
  //       "0 14px 26px -12px rgba(85, 172, 238, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 172, 238, 0.2)"
  //   }
  // },
  // facebook: {
  //   backgroundColor: "#3b5998",
  //   color: "#fff",
  //   boxShadow:
  //     "0 2px 2px 0 rgba(59, 89, 152, 0.14), 0 3px 1px -2px rgba(59, 89, 152, 0.2), 0 1px 5px 0 rgba(59, 89, 152, 0.12)",
  //   "&:hover,&:focus": {
  //     backgroundColor: "#3b5998",
  //     color: "#fff",
  //     boxShadow:
  //       "0 14px 26px -12px rgba(59, 89, 152, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(59, 89, 152, 0.2)"
  //   }
  // },
  // google: {
  //   backgroundColor: "#dd4b39",
  //   color: "#fff",
  //   boxShadow:
  //     "0 2px 2px 0 rgba(221, 75, 57, 0.14), 0 3px 1px -2px rgba(221, 75, 57, 0.2), 0 1px 5px 0 rgba(221, 75, 57, 0.12)",
  //   "&:hover,&:focus": {
  //     backgroundColor: "#dd4b39",
  //     color: "#fff",
  //     boxShadow:
  //       "0 14px 26px -12px rgba(221, 75, 57, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(221, 75, 57, 0.2)"
  //   }
  // },
  // github: {
  //   backgroundColor: "#333333",
  //   color: "#fff",
  //   boxShadow:
  //     "0 2px 2px 0 rgba(51, 51, 51, 0.14), 0 3px 1px -2px rgba(51, 51, 51, 0.2), 0 1px 5px 0 rgba(51, 51, 51, 0.12)",
  //   "&:hover,&:focus": {
  //     backgroundColor: "#333333",
  //     color: "#fff",
  //     boxShadow:
  //       "0 14px 26px -12px rgba(51, 51, 51, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(51, 51, 51, 0.2)"
  //   }
  // },
  // simple: {
  //   "&,&:focus,&:hover,&:visited": {
  //     color: "#FFFFFF",
  //     background: "transparent",
  //     boxShadow: "none"
  //   },
  //   "&$primary": {
  //     "&,&:focus,&:hover,&:visited": {
  //       color: primaryColor
  //     }
  //   },
  //   "&$info": {
  //     "&,&:focus,&:hover,&:visited": {
  //       color: infoColor
  //     }
  //   },
  //   "&$success": {
  //     "&,&:focus,&:hover,&:visited": {
  //       color: successColor
  //     }
  //   },
  //   "&$warning": {
  //     "&,&:focus,&:hover,&:visited": {
  //       color: warningColor
  //     }
  //   },
  //   "&$rose": {
  //     "&,&:focus,&:hover,&:visited": {
  //       color: roseColor
  //     }
  //   },
  //   "&$danger": {
  //     "&,&:focus,&:hover,&:visited": {
  //       color: dangerColor
  //     }
  //   },
  //   "&$twitter": {
  //     "&,&:focus,&:hover,&:visited": {
  //       color: "#55acee"
  //     }
  //   },
  //   "&$facebook": {
  //     "&,&:focus,&:hover,&:visited": {
  //       color: "#3b5998"
  //     }
  //   },
  //   "&$google": {
  //     "&,&:focus,&:hover,&:visited": {
  //       color: "#dd4b39"
  //     }
  //   },
  //   "&$github": {
  //     "&,&:focus,&:hover,&:visited": {
  //       color: "#333333"
  //     }
  //   }
  // }
};

export default appointmentCardStyle;
