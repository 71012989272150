import React from "react";
// material-ui components
import {withStyles} from "@material-ui/core/styles";
import appointmentCardStyle from "../assets/jss/material-kit-react/components/appointmentCardStyle";

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CancelIcon from '@material-ui/icons/Cancel';
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";

class AppointmentConfirmation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      appointmentTimeCorrect: false,
      meetingInformationCorrect: false,
      emailCorrect: false,
      allInfoProvided: false,
    }
    this.componentDidMount = this.componentDidMount.bind(this)
  }

  async componentDidMount() {
    await this.initConfirmData()
    await this.returnConfirmationStatus()
  }

  initConfirmData = () => {
    const checkApptTime = this.props.initConfirmData.event.length > 0
    const appointmentInfo = this.props.initConfirmData.appointmentInfo
    let checkTitle = false
    let checkLocation = false
    let checkEmail = false
    if (Object.keys(appointmentInfo).length >= 1) {
      checkTitle = appointmentInfo.title.valid
      checkLocation = appointmentInfo.location.valid
      checkEmail = appointmentInfo.email.valid
    }
    this.setState({
      appointmentTimeCorrect: checkApptTime,
      meetingInformationCorrect: (checkTitle && checkLocation),
      emailCorrect: checkEmail,
      allInfoProvided: (checkEmail && checkTitle && checkLocation && checkApptTime)
    })
  }

  returnConfirmationStatus = () => {
    this.props.confirmInfo(
      this.state.allInfoProvided
    )
  }

// ToDO, remove the ascii icon and use material icon instead.
  render() {
    const {classes} = this.props
    const confirmDescription = (this.state.allInfoProvided)
      ? <Typography variant='caption'>Thank you for providing the information!
        <br/> <br/> Please click &quot;Confirm&quot; to generate the meeting invite.</Typography>
      : 'Information incomplete. Please check above and try again.'

    const checkBoxIcon = <CheckBoxIcon style={{color: '#4caf50'}}/>
    const wrongIcon = <CancelIcon style={{color: '#999999'}}/>
    return (
      <div className={classes.cardContainer}>
        <Card>
          <CardBody>
            <Typography> {this.state.appointmentTimeCorrect ? checkBoxIcon : wrongIcon} Appointment Time</Typography>
            <Typography> {this.state.emailCorrect ? checkBoxIcon : wrongIcon} E-Mail Address </Typography>
            <Typography> {this.state.meetingInformationCorrect ? checkBoxIcon : wrongIcon} Meeting
              Information </Typography>
            {confirmDescription}
          </CardBody>
        </Card>
        <br/>
        <br/>
        <br/>
      </div>
    )
  }
}

export default withStyles(appointmentCardStyle)(AppointmentConfirmation)
