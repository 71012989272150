import React from 'react';
import {Box, TextField} from '@material-ui/core';
import moment from 'util/moment';
import {Autocomplete} from "@material-ui/lab";

const removeUnderscore = function (str = '') {
  return str.replace(/[_]/gi, ' ');
};

const timezones = moment.tz.names().map((label, index) => ({
  label: removeUnderscore(label),
  index: index,
  value: label
}));

const findTimezoneByValue = (value) => {
  let filtered = timezones.filter(tz => tz.value === value)
  if (filtered.length > 0) {
    return filtered[0]
  } else {
    return ''
  }
}

const Timezones = ({value, onChange, ...props}) => {
  return (
    <Box sx={{width: "300px", padding: "10px"}}{...props}>
      <Autocomplete
        id="autocomplete-timezone"
        options={timezones}
        getOptionLabel={(option) => (option ? option.label : '')}
        value={findTimezoneByValue(value)}
        renderInput={
          (params) =>
            <TextField {...params} fullWidth label="Timezones"/>
        }
        onChange={(e, option) => {
          if (option) {
            onChange(option.value)
          }
        }}
      />
    </Box>
  );
};

export default Timezones;
