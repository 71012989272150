/* eslint react/no-multi-comp:0, no-console:0 */

import React from 'react'
import {Calendar, momentLocalizer} from 'react-big-calendar'
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import Chips from "./Chips/Chips";
import {GetContent} from "../util/githubStore";
import {currentTimezone, normalizeDates} from 'util/dateUtils';
import Timezones from "./Timezones/Timezones";
import moment from 'util/moment'

// Overall style
import {withStyles} from "@material-ui/styles";
import scheduleCalendarStyle from "assets/jss/material-kit-react/components/scheduleCalendarStyle"
// Calendar specific style
import 'assets/scss/ranged-calendar/ranged-calendar.scss'

class ScheduleCalendar extends React.Component {
  constructor(...args) {
    super(...args)
    this.state = {
      events: [],
      balloon: Boolean(false),
      balloonMessage: 'success',
      balloonSeverity: 'success',
      timezone: currentTimezone
    }
    this.handleSelectSlot = this.handleSelectSlot.bind(this);
    this.saveUserSelectedSlots = this.saveUserSelectedSlots.bind(this);
  }

  initUserSelectedSlots = () => {
    if (this.props.initUserSelectedSlots.length > 0) {
      this.setEvents([...this.state.events, ...this.props.initUserSelectedSlots])
    }
  }

  async fetchCalendarData() {
    try {
      const resp = await GetContent('private/calendarEvents/myEvents.json')
      const resp_json = await JSON.parse(resp)
      this.setEvents(resp_json)
    } catch (err) {
      console.log(`Something broke while getting events ${err}`)
    }
  }

  async componentDidMount() {
    await this.fetchCalendarData()
    this.initUserSelectedSlots()
  }

  generateId() {
    let date = new Date()
    return `${date.toISOString()}-${Math.floor(Math.random() * 1000)}`
  }

  saveUserSelectedSlots(events) {
    this.props.userSelectedSlots(events.filter(event => event.type === 'userSelected'))
  }

  async handleSelectSlot(event) {
    if (await this.handleSelectBookingRules(event)) {
      return true
    } else {
      let eventObj = {
        id: this.generateId(),
        title: '',
        start: event.start,
        end: event.end,
        type: 'userSelected'
      }
      const addedEvents = [...this.state.events, eventObj,]
      this.saveUserSelectedSlots(addedEvents)
      this.setEvents(addedEvents)

    }
  }

  async handleSelectBookingRules(event) {
    // This function must return false in order to stop booking confirmation.
    // Dates booking before today, or any overlapping appointments will not be accepted.
    // This was actually a difficult practice problem to solve.
    // https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
    let {start, end, slots} = event

    function checkViolation(ele) {
      return (new Date() > start) || (Math.max(start.getTime(), ele.start.getTime()) < Math.min(end.getTime(), ele.end.getTime()))
    }

    if (slots.length > 5) {
      await this.displayMessage('info', 'Cannot book more than 2 hours at a time.')
      return true
    }
    if (this.state.events.some(checkViolation)) {
      await this.displayMessage('info', 'Unable to book this time frame.')
      return true
    }

    let userBookedEvents = this.state.events.filter(events => events.type === 'userSelected');
    if (userBookedEvents.length > 0) {
      await this.displayMessage('warning', 'To save your time, let us limit to 1 appointments for now :)')
      return true
    }
    return false
  }

  hideMessage = () => {
    this.setState({
        balloon: Boolean(false)
      }
    )
  }

  displayMessage = (severity, message) => {
    severity = severity || 'success';
    message = message || 'success'
    this.setState({
        balloon: Boolean(true),
        balloonMessage: message,
        balloonSeverity: severity
      }
    )
  }

  handleDelete(selectedEvent) {
    let filteredEvents = this.state.events.filter((event) => selectedEvent.id !== event.id && selectedEvent.action === 'remove')
    this.setState({
      events: [...filteredEvents],
    })
    this.saveUserSelectedSlots([...filteredEvents])
  }

  setTimezone(timezone) {
    this.setState({timezone: timezone})
  }

  setEvents(events, startField = 'start', endField = 'end') {
    this.setState({events: normalizeDates(events, startField, endField)})
  }

  render() {
    const {classes} = this.props;
    const unavailable = new RegExp('Unavailable')
    let mm = moment.tz.setDefault(this.state.timezone)
    let localized = momentLocalizer(mm)

    return (
      <div id='rangedCalendarRoot'>
        <Timezones
          className="control"
          value={this.state.timezone}
          onChange={val => this.setTimezone(val)}/>
        <div className={classes.chipContainer}>
          {this.state.events.map((data) => {
            if (data.type === 'userSelected') {
              let start = data.start.getTime()
              let formatDate =  mm(start).format('MMMM DD')
              let formatStart =  mm(start).format('h:mmA')
              let formatEnd =  mm(data.end.getTime()).format('h:mmA')
              return (
                <Chips
                  key={data.id}
                  label={`${formatDate}: ${formatStart} - ${formatEnd}`}
                  onDelete={() => {
                    data.action = "remove";
                    this.handleDelete(data)
                  }}
                  className={classes}
                  color="facebook"
                />
              );
            } else {
              return ''
            }
          })}
        </div>
        <div>
          <Snackbar open={this.state.balloon} autoHideDuration={4000} onClose={this.hideMessage}>
            <Alert onClose={this.hideMessage} severity={this.state.balloonSeverity}>
              {this.state.balloonMessage}
            </Alert>
          </Snackbar>
          <Calendar
            eventPropGetter={event => ({
              style: {
                backgroundColor: event.title.match(unavailable)
                  ? "#797e7c"
                  : "#3b5998",
              }
            })}
            style={{minHeight: '400px'}}
            selectable
            defaultView={'week'}
            views={['month', 'week', 'day']}
            localizer={localized}
            events={this.state.events}
            scrollToTime={new Date(1970, 1, 1, 6)}
            defaultDate={new Date()}
            onSelectSlot={this.handleSelectSlot}
          />
        </div>
      </div>

    )
  }
}

const propTypes = {}
ScheduleCalendar.propTypes = propTypes

export default withStyles(scheduleCalendarStyle)(ScheduleCalendar)
