/* eslint react/no-multi-comp:0, no-console:0 */

// TODO - use some kind token auth to go to this page.
import React from 'react'
import {Calendar, momentLocalizer} from 'react-big-calendar'
import {currentTimezone, normalizeDates} from 'util/dateUtils';
import Timezones from "./Timezones/Timezones";
import moment from 'util/moment'

import RegularButton from "./CustomButtons/Button";
import PropTypes from "prop-types";
import {Snackbar} from "@material-ui/core";
import {Alert} from '@material-ui/lab';

import scheduleCalendarStyle from "assets/jss/material-kit-react/components/scheduleCalendarStyle"
import {withStyles} from "@material-ui/styles";
import 'assets/scss/ranged-calendar/ranged-calendar.scss'
import {SaveGit, GetContent} from "../util/githubStore";

class ManageMyCalendar extends React.Component {
  constructor(...args) {
    super(...args)
    this.state = {
      events: [],
      balloon: Boolean(false),
      balloonMessage: 'success',
      balloonSeverity: 'success',
      timezone: currentTimezone
    }
    this.saveCalendarData = this.saveCalendarData.bind(this);
  }

  async fetchCalendarData() {
    try {
      const resp = await GetContent('private/calendarEvents/myEvents.json')
      // const resp = await fetch('https://schanjr-io.github.io/private/calendarEvents/myEvents.json', {cache: 'no-cache'})
      return await JSON.parse(resp)
    } catch (err) {
      console.log(`Something broke while getting events ${err}`)
    }
  }

  async componentDidMount() {
    let resp = await this.fetchCalendarData()
    this.setEvents(resp)
  }

  componentWillUnmount() {
    this.setState({})
  }

  generateId() {
    let date = new Date()
    return `${date.toISOString()}-${Math.floor(Math.random() * 1000)}`
  }

  handleSelectSlot = ({start, end,}) => {
    const title = 'Stanley Unavailable (SGT)'
    if (title)
      this.setEvents([
        ...this.state.events,
        {
          start: start,
          end: end,
          title: title,
          id: this.generateId(),
        },
      ])
  }

  deleteEvent = (selectedEvent) => {
    let msg = `Delete event? ${selectedEvent.title}`
    let toDelete = window.confirm(msg);
    if (toDelete) {
      let filteredEvents = this.state.events.filter(event => event.id !== selectedEvent.id)
      this.setEvents([
        ...filteredEvents
      ])

    }
  }

  async saveCalendarData() {
    try {
      const fileContent = JSON.stringify(this.state.events)
      await SaveGit(fileContent, 'private/calendarEvents/myEvents.json')
      this.displayMessage('success', 'Added to github')
    } catch (e) {
      this.displayMessage('warning', `Try again later: ${e}`)
    }
  }

  hideMessage = () => {
    this.setState({
        balloon: Boolean(false)
      }
    )
  }

  displayMessage = (severity, message) => {
    severity = severity || 'success';
    message = message || 'success'
    this.setState({
        balloon: Boolean(true),
        balloonMessage: message,
        balloonSeverity: severity
      }
    )
  }

  handleClearAll = () => {
    this.setState({events: []})
  }

  setTimezone(timezone) {
    this.setState({timezone: timezone})
  }

  setEvents(events, startField = 'start', endField = 'end') {
    this.setState({events: normalizeDates(events, startField, endField)})
  }

  render() {
    const {classes} = this.props;
    let localized = momentLocalizer(moment.tz.setDefault(this.state.timezone))

    return (
      <div id='rangedCalendarRoot' className={classes.main}>
        <Timezones
          className="control"
          value={this.state.timezone}
          onChange={val => this.setTimezone(val)}/>
        <div style={{textAlign: "center"}}>
          <Snackbar open={this.state.balloon} autoHideDuration={4000} onClose={this.hideMessage}>
            <Alert onClose={this.hideMessage} severity={this.state.balloonSeverity}>
              {this.state.balloonMessage}
            </Alert>
          </Snackbar>
          <RegularButton onClick={this.saveCalendarData} size="sm" color="info">Save</RegularButton>
          <RegularButton onClick={this.handleClearAll} size="sm" color="warning">Clear all</RegularButton>
        </div>
        <Calendar
          // min={moment('6:00am', 'h:mma').tz('Asia/Singapore').toDate()}
          // max={moment('11:00pm', 'h:mma').tz('Asia/Singapore').toDate()}
          style={{minHeight: '400px'}}
          selectable
          defaultView={'week'}
          views={['week', 'day']}
          localizer={localized}
          events={this.state.events}
          scrollToTime={new Date(1970, 1, 1, 6)}
          defaultDate={new Date()}
          onSelectEvent={event => this.deleteEvent(event)}
          onSelectSlot={this.handleSelectSlot}
        />

      </div>
    )
  }
}

const propTypes = {
  events: PropTypes.array,
  balloon: PropTypes.bool,
  balloonSeverity: PropTypes.oneOf(["info", "success", "warning", "danger", "primary"]),
  balloonMessage: PropTypes.string
}

ManageMyCalendar.propTypes = propTypes

export default withStyles(scheduleCalendarStyle)(ManageMyCalendar)
