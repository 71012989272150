import LandingPage from "./views/LandingPage/LandingPage";
import SchedulePage from "./views/SchedulePage/SchedulePage";
import SavedSchedulePage from "./views/SavedSchedulePage/SavedSchedulePage";
import Gallery from "./views/Gallery/GalleryPage";

const routes = [
  {
    path: "/schedule",
    name: "Schedule",
    component: SchedulePage,
  },
  {
    path: "/schedule/me",
    name: "My Scheduler",
    component: SavedSchedulePage,
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: Gallery,
  },
  {
    path: "/",
    name: "home",
    component: LandingPage,
  },
];
export default routes;
