import {container, title} from "assets/jss/material-kit-react.js";

const schedulePageStyle = theme => ({
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  "@keyframes dropDown": {
    "0%": {bottom: "400px", opacity: 0.5},
    "50%": {bottom: "200px", opacity: 0.5},
    "100%": {bottom: "0px", opacity: 1},
  },
  animateDrop: {
    position: "relative",
    margin: "0 auto",
    "transform-origin": "10px 10px",
    animation: `$dropDown 1s ease-in forwards`,
    "animation-play-state": "running",
  },
  stepRoot: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '25em',
    backgroundColor: "#fff",
    color: "#3b5998",
  }
})

export default schedulePageStyle;
