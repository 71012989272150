import React from 'react';
import ReactDOM from 'react-dom';
import {createBrowserHistory} from 'history';
import {Router, Route, Switch, Redirect} from 'react-router-dom';

import {BrowserRouter} from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

import * as serviceWorker from './serviceWorker';
import routes from "./routes";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <BrowserRouter>
        {routes.map((prop, key) => {
          return (
            <Route
              exact path={prop.path}
              component={prop.component}
              key={key}
            />
          );
        })}
      </BrowserRouter>
    </Switch>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
