import React from 'react';
import classNames from "classnames";

import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/HeaderLinks";
import HeaderNavLinks from "../../components/HeaderNavLinks";
import Parallax from "../../components/Parallax/Parallax";
import ScheduleCalendar from "../../components/ScheduleCalendar";
import AppointmentCard from "../../components/AppointmentCard";
import AppointmentConfirmation from "../../components/AppointmentConfirmation";

import constants from "../../util/constants"
import emailjs from "emailjs-com"

// Stepper components.
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';

// Styles
import {withStyles} from '@material-ui/core/styles';
import styles from "assets/jss/material-kit-react/views/schedulePage.js";

import 'assets/scss/react-add-to-calendar/react-add-to-calendar.scss'
import Typography from "@material-ui/core/Typography";
import {Card} from "@material-ui/core";
import CardBody from "../../components/Card/CardBody";

// weird reactjs behavior that don't let you use the imported name directly in the React.Component functions.
const CONSTANTS = constants

class SchedulePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userSelectedSlots: [],
      userAppointmentInfo: {},
      activeStep: 0,
      hideConfirmButton: true,
    }
    this.handleUserSelectedSlots = this.handleUserSelectedSlots.bind(this)
    this.handleAppointmentInfo = this.handleAppointmentInfo.bind(this)
    this.hideButtonVisibility = this.hideButtonVisibility.bind(this)
  }

  handleUserSelectedSlots(events) {
    this.setState({userSelectedSlots: events})
  }

  handleAppointmentInfo(appointment) {
    this.setState({userAppointmentInfo: appointment})
  }

  handleConfirmInfo = (bool) => {
    this.setState({hideConfirmButton: !bool})
  }

  handleNext = () => {
    this.setState({activeStep: this.state.activeStep + 1})
  }

  handleBack = () => {
    this.setState({activeStep: this.state.activeStep - 1})
  }

  handleReset = () => {
    this.setState({activeStep: 0})
  }

  getSteps = () => {
    return ['Schedule a time', 'Meeting Information', 'Confirmation']
  }

  getStepContent = (step) => {
    switch (step) {
      case 0:
        return <ScheduleCalendar userSelectedSlots={this.handleUserSelectedSlots}
                                 initUserSelectedSlots={this.state.userSelectedSlots}/>;
      case 1:
        return <AppointmentCard appointmentInfo={this.handleAppointmentInfo}
                                initAppointmentInfo={this.state.userAppointmentInfo}/>;
      case 2:
        return <AppointmentConfirmation confirmInfo={this.handleConfirmInfo}
                                        initConfirmData={{
                                          event: this.state.userSelectedSlots,
                                          appointmentInfo: this.state.userAppointmentInfo
                                        }}/>;
      default:
        return 'Unknown Step';
    }
  }

  hideButtonVisibility() {
    return this.state.hideConfirmButton
  }

  formatCalendarICS = () => {
    let prep = {}
    if (this.state.hideConfirmButton === false) {
      this.state.userSelectedSlots.forEach((appointmentTime) => {
        prep.startTime = appointmentTime.start
        prep.endTime = appointmentTime.end
        prep.title = this.state.userAppointmentInfo.title.value
        prep.description = this.state.userAppointmentInfo.description.value
        prep.location = this.generateZoomLink(this.state.userAppointmentInfo.location.value)
        prep.schedulerEmail = this.state.userAppointmentInfo.email.value
        prep.myEmail = CONSTANTS.myEmail
      })
    }
    return this.formatGoogleTemplate(prep)
  }

  formatGoogleTemplate = (meta) => {
    if (meta.length === 0) {
      return ''
    }
    let urlParams = {}
    let url = 'https://calendar.google.com/calendar/render?action=TEMPLATE&'
    let startTime = meta.startTime.toISOString().replaceAll('-', '').replaceAll(':', '').replace('.000Z', 'Z')
    let endTime = meta.endTime.toISOString().replaceAll('-', '').replaceAll(':', '').replace('.000Z', 'Z')
    urlParams.dates = `${startTime}/${endTime}`
    urlParams.location = meta.location
    urlParams.text = meta.title
    urlParams.details = meta.description
    urlParams.add = `${meta.schedulerEmail},${meta.myEmail}`
    return `${url}${new URLSearchParams(urlParams).toString()}`
  }

  sendMails(generatedICS) {
    let emailContent = {}
    const schedulerEmail = this.state.userAppointmentInfo.email.value
    emailContent.from_name = schedulerEmail
    emailContent.to_name = 'Stanley'
    emailContent.message = generatedICS
    emailContent.reply_to = schedulerEmail
    emailjs.send(CONSTANTS.emailServiceId, CONSTANTS.emailTemplateId, emailContent, CONSTANTS.emailUserId)
      .then(() => {
        // console.info(result.status)
        // console.info(result.text)
      }, (error) => {
        console.error(error)
        window.alert(`Very sorry. There was an issue sending invite to Stanley. Please email him instead. ${CONSTANTS.myEmail}`)
      });
  }

  generateZoomLink(locationValue) {
    if (locationValue === '') {
      return CONSTANTS.zoomMeetingURL
    }
    return locationValue
  }

  render() {
    const {classes} = this.props;
    const steps = this.getSteps();
    return (
      <div>
        <Header
          color="transparent"
          rightLinks={<HeaderLinks/>}
          leftLinks={<HeaderNavLinks/>}
          fixed
          changeColorOnScroll={{
            height: 100,
            color: "white"
          }}
        />
        <Parallax small filter image={require("assets/img/landing-page/landing-page1.jpg").default}/>
        <div className={classNames(classes.main, classes.stepRoot)}>
          <Stepper activeStep={this.state.activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <div>{this.getStepContent(index)}</div>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={this.state.activeStep === 0}
                        onClick={this.handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleNext}
                        className={classes.button}
                        disabled={(this.state.activeStep === steps.length - 1 && this.state.hideConfirmButton)}
                      >
                        {this.state.activeStep === steps.length - 1 ? 'Confirm' : 'Next'}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {
            (this.state.activeStep === this.getSteps().length) ? (
              <Card className={classes.cardContainer}>
                <CardBody>
                  <Typography variant='caption'>
                    <p>Please click the generated link <a href={this.formatCalendarICS()} target="_blank" rel="noreferrer">here.</a></p>
                  </Typography>
                  {this.sendMails(this.formatCalendarICS())}
                </CardBody>
              </Card>
            ) : ''
          }
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(SchedulePage);
