/*eslint-disable*/
import React from "react";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import {Dehaze, DateRange, Home, PhotoLibraryRounded} from "@material-ui/icons";
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Dehaze}
          dropdownList={[
            <Link className={classes.dropdownLink} to="/">
              <Home/> Home
            </Link>,
            <Link className={classes.dropdownLink} to="/gallery">
              <PhotoLibraryRounded/> Gallery
            </Link>,
            <Link className={classes.dropdownLink} to="/schedule">
              <DateRange/> Let's Meet
            </Link>,
          ]}
        />
      </ListItem>
    </List>
  );
}
