import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import makeStyles from "@material-ui/core/styles/makeStyles";
import Chip from '@material-ui/core/Chip';

// core components
import chipStyle from "assets/jss/material-kit-react/components/chipsStyle.js";

const makeComponentStyles = makeStyles(() => ({
  ...chipStyle
}));

const Chips = React.forwardRef((props, __ref) => {
  const {
    avatar,
    clickable,
    className,
    color,
    component,
    deleteIcon,
    disabled,
    icon,
    size,
    variant,
    // label,
    // onDelete,
    ...rest
  } = props;

  const classes = makeComponentStyles();

  const chipsClasses = classNames({
    [classes[color]]: color,
    [classes[size]]: size,
    [classes[variant]]: variant,
    [className]: className,
    [classes.avatar]: avatar,
    [classes.color]: color,
    [classes.clickable]: clickable,
    [classes.component]: component,
    [classes.deleteIcon]: deleteIcon,
    [classes.disabled]: disabled,
    [classes.icon]: icon,
    // [classes.label]: label,
    // [classes.onDelete]: onDelete
  });
  return (
    <Chip {...rest} className={chipsClasses}>
    </Chip>
  );
});

Chips.defaultProps = {
  outlined: "outlined",
  color: "primary",
  label: "testing label"
};

Chips.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "facebook",
    "twitter",
    "google",
    "github",
    "transparent"
  ]),
  size: PropTypes.oneOf(["medium", "small"]),
  variant: PropTypes.oneOf(["outlined", "default"]),
  avatar: PropTypes.element,
  clickable: PropTypes.bool,
  component: PropTypes.elementType,
  deleteIcon: PropTypes.element,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  label: PropTypes.node,
  onDelete: PropTypes.func,
};

export default Chips;
