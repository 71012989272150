/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import {Link} from "react-router-dom";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import {Apps, GitHub, LinkedIn} from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Side Projects"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <a
              href="https://sg-housing.streamlit.app/"
              target="_blank"
              className={classes.dropdownLink}
            >
              A Graph of SG Housing
            </a>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="linkedin"
          title="Find me on LinkedIn"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{tooltip: classes.tooltip}}
        >
          <Button
            href="https://www.linkedin.com/in/schanjr/"
            color="transparent"
            target="_blank"
            className={classes.navLink}
          >
            <LinkedIn className={classes.icons}/>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="linkedin"
          title="Find me on Github"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{tooltip: classes.tooltip}}
        >
          <Button
            href="https://github.com/schanjr"
            color="transparent"
            target="_blank"
            className={classes.navLink}
          >
            <GitHub className={classes.icons}/>
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
