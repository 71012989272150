import React from "react";
import {makeStyles} from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/HeaderLinks.js";
import HeaderNavLinks from "components/HeaderNavLinks";
// import TagEmbedInstagram from "components/InstagramWidgets/TagEmbed";
import EmbedSocial from "components/InstagramWidgets/EmbedSocial";

import styles from "assets/jss/material-kit-react/views/galleryPage.js";

// const dashboardRoutes = [];
const useStyles = makeStyles(styles);

function Gallery(props) {
  const classes = useStyles(styles);
  const {...rest} = props;

  return (
    <div>
      <Header
        classname={classes.main}
        color="white"
        // routes={dashboardRoutes}
        rightLinks={<HeaderLinks/>}
        leftLinks={<HeaderNavLinks/>}
        flex
        // changeColorOnScroll={{
        //   height: 200,
        //   color: "dark"
        // }}
        {...rest}
      />
      <EmbedSocial/>
    </div>
  )
}

export default Gallery
