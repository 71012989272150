import React from 'react';
import classNames from "classnames";

import Header from "components/Header/Header";
import HeaderLinks from "components/HeaderLinks";
import HeaderNavLinks from "components/HeaderNavLinks";
import Parallax from "components/Parallax/Parallax";
import ManageMyCalendar from "components/ManageMyCalendar";

// Styles
import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/material-kit-react/views/savedSchedulePage.js";

class SavedSchedulePage extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.main)}>
        <Header
          color="transparent"
          rightLinks={<HeaderLinks/>}
          leftLinks={<HeaderNavLinks/>}
          fixed
          changeColorOnScroll={{
            height: 100,
            color: "white"
          }}
        />
        <Parallax small filter image={require("assets/img/landing-page/landing-page1.jpg").default}/>
          <div className={classNames(classes.mainCalendar)}>
            <ManageMyCalendar/>
          </div>
      </div>
    )
  }
}

const propTypes = {}
SavedSchedulePage.propTypes = propTypes

export default withStyles(styles)(SavedSchedulePage);
