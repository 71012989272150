import React from "react";
import {makeStyles} from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/HeaderLinks.js";
import HeaderNavLinks from "components/HeaderNavLinks";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

const dashboardRoutes = [];
const useStyles = makeStyles(styles);

function LandingPage(props) {
  const classes = useStyles(styles);
  const { ...rest } = props;

  return (
    <div>
      <Header
        classname={classes}
        color="transparent"
        routes={dashboardRoutes}
        brand="Taken @ Ha Long Bay, Vietnam."
        rightLinks={<HeaderLinks/>}
        leftLinks={<HeaderNavLinks/>}
        fixed
        changeColorOnScroll={{
        height: 400,
        color: "white"
      }}
      {...rest}
      />
      <Parallax image={require("assets/img/landing-page/landing-page1.jpg").default}>
      </Parallax>
      {/*<Footer />*/}
    </div>
  )
}

export default LandingPage
